import React from "react"

export default function TournamentSelectionOptions() {
    return (<>
        <option value="">-- Choose a tournament --</option>

        <option value="Stafford_2023_1014">
            Stafford - October 14th & 15th @ Stafford Squash
        </option>
        <option value="Bundaberg_2023_1104">
            Bundaberg - November 4th & 5th @ Bundaberg Squash Centre
        </option>
        <option value="AlexHills_2023_1125">
            Alex Hills - November 25th & 26th @ Goodlife Health Club
        </option>
        <option value="Redcliffe_2023_1202">
            Redcliffe - December 2nd & 3rd @ Scarborough Squash Club PCYC
        </option>

    </>)
}
