import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSortBy, useTable } from "react-table"

export default function ListTournamentEntries({ tournamentName }) {
  const apiUrl =
    "https://e3hrge7vah.execute-api.ap-southeast-2.amazonaws.com/prod/get-entries/" +
    tournamentName

  const [entries, getEntries] = useState([])

  const getEntriesAPI = useCallback(() => {
    fetch(apiUrl)
      .then(res => res.json())
      .then(res => {
        getEntries(res)
      })
  }, [apiUrl])

  useEffect(() => {
    getEntriesAPI()
  }, [getEntriesAPI])

  const data = useMemo(() => entries, [entries])

  const columns = useMemo(
    () => [
      {
        Header: "Player Name",
        accessor: "PlayerName", // accessor is the "key" in the data
      },
      {
        Header: "Player Club Name",
        accessor: "PlayerClubName",
      },
      {
        Header: "Entry Submit Time",
        accessor: "EntrySubmitTime",
      },
      {
        Header: "Entry Notes",
        accessor: "EntryNotes",
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  if (entries) {
    return (
      <>
        <div>
          {data.length}
          {data.length === 1 ? <> player has </> : <> players have </>}
          entered the <em>{tournamentName}</em> tournament
          <table {...getTableProps()} style={{ border: "solid 1px maroon" }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(columns => (
                    <th
                      {...columns.getHeaderProps(
                        columns.getSortByToggleProps()
                      )}
                      style={{
                        borderBottom: "solid 3px maroon",
                        background: "black",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {columns.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {columns.isSorted
                          ? columns.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px maroon",
                            background: "floralwhite",
                            color: "black",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  } else {
    return <div />
  }
}
