import React from "react"
import { Link } from "gatsby"
import qmsaLogo from "../../static/qmsa-logo-iPad Pro.png"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function Layout({ children }) {
  const { title, titleTemplate, description, siteUrl, image } =
    useSiteMetadata()

  return (
    <div style={{ margin: `3rem auto`, maxWidth: 650, padding: `0 1rem` }}>
      <header>
        <Helmet title={title} titleTemplate={titleTemplate}>
          <meta name="description" content={description} />
          <meta name="image" content={image} />
          {siteUrl && <meta property="og:url" content={siteUrl} />}

          {title && <meta property="og:title" content={title} />}
          {description && (
            <meta property="og:description" content={description} />
          )}
          {image && <meta property="og:image" content={image} />}
        </Helmet>

        <Link to="/">
          <img src={qmsaLogo} alt="QMSA logo" />
        </Link>
      </header>

      {children}

      <footer>
        <div style={{ fontSize: "75%" }}>
          Powered by volunteers who love squash.{" "}
          <a href="https://ourvoice.qmsa.asn.au/powered-by/">Join us.</a>
        </div>
      </footer>
    </div>
  )
}
