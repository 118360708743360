import React from "react"
import Layout from "../components/layout"
import Greeting from "../components/greeting"
import { useState } from "react"
import ListTournamentEntries from "../services/get-entries"
import TournamentSelectionOptions from "../components/tournament-selection-options"

export default function Home() {
  const [tournament, setTournament] = useState({
    name: "",
  })

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleGetEntries = e => {
    e.preventDefault()
    setIsSubmitted(true)
  }

  return (
    <Layout>
      <Greeting playerName="Administrator" />

      {!isSubmitted && (
        <form onSubmit={handleGetEntries}>
          <div>
            <label>
              Tournament
              <br />
              <select
                required
                style={{ width: "100%" }}
                value={tournament.name}
                onChange={event =>
                  setTournament({ ...tournament, name: event.target.value })
                }
              >
                <TournamentSelectionOptions />
              </select>
            </label>
          </div>
          <br />

          <button type="submit">Get Entries</button>
        </form>
      )}

      {isSubmitted && (
        <>
          <table>
            <tbody>
              <tr>
                <td>
                  <a href="/">
                    <button type="button">Choose Tournament</button>
                  </a>
                </td>
                <td>
                  <a href="https://ourvoice.qmsa.asn.au/">
                    <button type="button">Exit</button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <ListTournamentEntries tournamentName={tournament.name} />

          <table>
            <tbody>
              <tr>
                <td>
                  <a href="/">
                    <button type="button">Choose Tournament</button>
                  </a>
                </td>
                <td>
                  <a href="https://ourvoice.qmsa.asn.au/">
                    <button type="button">Exit</button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Layout>
  )
}
